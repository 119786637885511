<template>
  <div class="container">
    <CommonTheBox>
      <h1 class="h2 center">Вход в личный кабинет</h1>
      <form
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="handleSubmit"
      >
        <div :class="$style.wrap">
          <div v-if="errors.detail" :class="$style.error">
            {{ detailError }}
          </div>
          <div :class="$style.inputs">
            <UiInputVInput
              v-model="email"
              label="Ваш Email"
              type="email"
              autocomplete="email"
              placeholder="info@yandex.ru"
              required
              :error="errors.email"
              @update:model-value="clearError('email')"
            />
            <CommonInputPassword
              v-model="password"
              label="Пароль"
              autocomplete="current-password"
              placeholder="Пароль"
              required
              :error="errors.password"
              @update:model-value="clearError('password')"
            />
          </div>
          <NuxtLink to="/forget" :class="$style.forget"
            >Забыли пароль?
          </NuxtLink>

          <UiCheckboxVCheckbox v-model="remember" :class="$style.checkbox">
            <span>Запомнить данные</span>
          </UiCheckboxVCheckbox>
          <div v-if="captchaLogin" :class="$style.captcha">
            <ClaimCaptcha @input="captcha = $event" />
          </div>
          <div :class="$style.action">
            <UiButtonVButton
              :class="$style.btn"
              type="submit"
              :loading="isLoading"
            >
              Войти
            </UiButtonVButton>
            <NuxtLink to="/register" :class="$style.link"
              >Зарегистрироваться
            </NuxtLink>
          </div>
        </div>
      </form>
    </CommonTheBox>
  </div>
</template>

<script setup lang="ts">
import { useFormErrors } from "~/composables/useFormErrors";
import ClaimCaptcha from "~/components/pages/claim/ClaimCaptcha.vue";

definePageMeta({
  middleware: ["guest"],
});

const email = ref("");
const password = ref("");
const remember = ref(false);
const isLoading = ref(false);

const { captchaLogin } = useSettings();
const captcha = ref<string | null>(null);

const { errors, setErrors, clearError, hasErrors, clearErrors } =
  useFormErrors();

async function handleSubmit() {
  if (isLoading.value) {
    return;
  }
  try {
    clearErrors();
    isLoading.value = true;
    const credentials: {
      email: string;
      password: string;
      recaptcha?: string;
    } = {
      email: email.value,
      password: password.value,
    };

    if (captchaLogin.value) {
      credentials.recaptcha = captcha.value || "";
    }
    await useAuth().login(credentials);
  } catch (error: any) {
    setErrors(error.data);
  } finally {
    isLoading.value = false;
  }
}

const detailError = computed(() => errors.value.detail);

// watch(detailError, (value) => {
//   if (value) {
//     setTimeout(() => {
//       clearError("detail");
//     }, 3000);
//   }
// });
</script>

<style lang="scss" module>
.wrap {
  padding: 3rem 7.2rem 0;

  @include respond-to(xs) {
    padding: 3rem 0 0;
  }
}

.error {
  color: $brand-red;
  margin-bottom: 2rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 0.8rem;
}

.forget {
  display: inline-block;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.checkbox {
  margin-bottom: 3.2rem;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.captcha {
  margin-bottom: 2rem;
}

.btn {
  width: 100%;
}

.link {
  text-decoration: underline;
}
</style>
